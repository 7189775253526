export const ITS_MESSAGE_EXTERNAL_SESSION_STRINGS: Record<string, string> = {
  eng:
    "Please use this link to download the remote software and start a session. This tool allows you to share your screen and give the engineering support team access to help you.",
  spa:
    "Utilice este enlace para descargar el software remoto e iniciar una sesión. Esta herramienta le permite compartir su pantalla y dar acceso al equipo de soporte técnico para que le ayude.",
  deu:
    "Bitte benutzen Sie diesen Link, um die Remote-Software herunterzuladen und eine Sitzung zu starten. Mit diesem Tool können Sie Ihren Bildschirm teilen und dem technischen Support-Team Zugriff gewähren, um Ihnen zu helfen.",
  por:
    "Use este link para baixar o software remoto e iniciar uma sessão. Essa ferramenta permite que você compartilhe sua tela e dê acesso à equipe de suporte de engenharia para ajudá-lo.",
  fra:
    "Veuillez utiliser ce lien pour télécharger le logiciel à distance et démarrer une session. Cet outil vous permet de partager votre écran et de donner accès à l'équipe de soutien technique pour vous aider.",
  ita:
    "Utilizza questo link per scaricare il software remoto e avviare una sessione. Questo strumento ti consente di condividere lo schermo e di fornire al team di supporto tecnico l'accesso per aiutarti.",
  jpn:
    "このリンクを使用してリモートソフトウェアをダウンロードし、セッションを開始してください。このツールを使用すると、画面を共有したり、エンジニアリングサポートチームにアクセスして支援してもらうことができます。",
  kor:
    "이 링크를 사용하여 원격 소프트웨어를 다운로드하고 세션을 시작하십시오.이 도구를 사용하면 화면을 공유하고 엔지니어링 지원 팀에 도움을 줄 수 있습니다.",
  zho:
    "请使用此链接下载远程软件并启动会话。此工具允许您共享屏幕，并允许工程支持团队访问以帮助您。",
};
